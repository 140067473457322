import React, { useContext, useRef, useEffect } from "react"
import { graphql } from "gatsby"

import { MainContext } from "context/context"
import PageWrapper from 'components/globals/pageWrapper'
import ParallaxSection from 'components/globals/parallaxSection'

import Link from 'components/globals/link'

import Button from 'components/globals/button'

import { formatDate, getReadLength, stripHtml } from "utils"

export default ({ data }) => {
    const {
        post: {
            databaseId,
            title,
            content,
            excerpt,
            featuredImage,
            date,
            author,
            categories,
            postFields: {
                heroBackgroundImage
            }
        },
        posts
    } = data.siteData

    const elementRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const otherPostsToShow = []

    posts.edges.forEach(({ node: otherPost }) => {
        if (otherPost.databaseId !== databaseId && otherPostsToShow.length < 3) {
            otherPostsToShow.push(otherPost)
        }
    })

    return (
        <PageWrapper
            title={title}
            description={stripHtml(excerpt)}
            image={featuredImage ? featuredImage.mediaItemUrl : null}
        >
            <section
                className={'single-hero'}
                ref={ref => elementRef.current = ref}
            >
                <ParallaxSection
                    backgroundUrl={heroBackgroundImage ? heroBackgroundImage.mediaItemUrl : ''}
                >
                    <div className={'container'}>
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={'meta'}>
                            <span className={'category-tag'}>
                                {categories.edges[0].node.name}
                            </span>
                            <span>
                                {formatDate(date)}
                            </span>
                        </div>
                    </div>
                </ParallaxSection>
            </section>
            <section className={'single-content container'}>
                <div className={'top'}>
                    <div>
                        <Link to={'/thoughts'}>
                            <i className={'fe fe-chevron-left'} />
                            <span>
                                Back to posts
                            </span>
                        </Link>
                    </div>
                    <div />
                    <div>
                        <a>
                            <span>
                                <i className={'fe fe-user'} />
                                {author.firstName} {author.lastName}
                                <i className={'fe fe-clock'} />
                                {getReadLength(content)} read
                            </span>
                        </a>
                    </div>
                </div>
                <div className={'copy medium'} dangerouslySetInnerHTML={{ __html: content }} />
                <div className={'post-footer'}>
                    <div className={'author'}>
                        <div>
                            <h3>
                                {author.firstName} {author.lastName}
                            </h3>
                            {author.description &&
                                <div className={'copy'} dangerouslySetInnerHTML={{ __html: author.description }} />
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className={'single-blog-grid'}>

            </section>
        </PageWrapper>
    )
}


export const query = graphql`
  query($databaseId: ID!) {
    siteData {
      post(id: $databaseId, idType: DATABASE_ID) {
        id
        databaseId
		title
		slug
        date
        link
        author {
            ...on WPGraphQL_User {
                firstName
                lastName
                description
            }
        }
        postFields {
            heroBackgroundImage {
                mediaItemUrl
            }
        }
        content(format: RENDERED)
        categories {
          edges {
            node {
              name
              slug
            }
          }
        }
        excerpt(format: RENDERED)
        featuredImage {
          altText
          title(format: RENDERED)
          mediaItemUrl
          slug
		}
      }
      posts {
        edges {
          node {
            id
            databaseId
            link
			title
			slug
            excerpt
            date
            featuredImage {
              mediaItemUrl
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`